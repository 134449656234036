import Vue from 'vue';
import { addMinutes, format, isAfter } from 'date-fns';

Vue.prototype.$formatDateTime = datetime =>
  format(new Date(datetime), 'yyyy-MM-dd HH:mm');
Vue.prototype.$formatTime = datetime => format(new Date(datetime), 'HH:mm');
Vue.prototype.$formatDate = datetime =>
  format(new Date(datetime), 'yyyy-MM-dd');

/**
 * Given a datetime, this function will return age formatted as -yrs., -mths., -d.
 * If one of the values is equal to zero, it will be omitted. Examples:
 *
 * 3d.
 * 4mth, 3d.
 * 5yrs, 8d.
 * 5yrs, 10mth.
 *
 * If the two date is today, the returned string will be, "born today". If the date
 * is in the future, the string returned will be, "not yet born".
 *
 * Note that cases such as current date = 01/01/2030 and birth-date = 31/12/2029
 *
 * will return "1d" (1 day), not 1 year!
 *
 * @param datetime
 * @returns string
 */
Vue.prototype.$getAge = birthDateString => {
  const birthDate = new Date(
    new Date(birthDateString).toISOString().substr(0, 10)
  );
  const currentDate = new Date();

  const startYear = birthDate.getFullYear();
  const february =
    (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0
      ? 29
      : 28;
  const daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  let yearDiff = currentDate.getFullYear() - startYear;
  let monthDiff = currentDate.getMonth() - birthDate.getMonth();
  let dayDiff = currentDate.getDate() - birthDate.getDate();

  if (monthDiff < 0) {
    yearDiff--;
    monthDiff += 12;
  }

  if (dayDiff < 0) {
    if (monthDiff > 0) {
      monthDiff--;
    } else {
      yearDiff--;
      monthDiff = 11;
    }
    dayDiff += daysInMonth[birthDate.getMonth()];
  }

  if (birthDate > currentDate) {
    return 'not yet born';
  }
  if (yearDiff === 0 && monthDiff === 0 && dayDiff === 0) {
    return 'born today';
  }

  yearDiff = yearDiff > 0 ? `${yearDiff}yr. ` : '';
  monthDiff = monthDiff > 0 ? `${monthDiff}mth. ` : '';
  dayDiff = dayDiff > 0 ? `${dayDiff}d.` : '';

  return yearDiff + monthDiff + dayDiff;
};

Vue.prototype.$checkIfTimeHasPassed = (datetime, margin) => {
  const compareTo = new Date(datetime);
  let compareWith = new Date();
  if (margin) {
    compareWith = addMinutes(compareWith, margin);
  }
  return isAfter(compareWith, compareTo);
};
