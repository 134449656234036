<template>
  <div class="">
    <desc-list>
      <desc-item>
        <desc-title> Status </desc-title>
        <desc-data>
          <p>
            {{ question.status == 1 ? 'Published' : 'Unpublished' }}
          </p>
          <p>
            {{ question.answered == 1 ? 'Answered' : 'Unanswered' }}
          </p>
        </desc-data>
      </desc-item>
      <desc-item>
        <desc-title> Animal </desc-title>
        <desc-data>
          <p>{{ question.animal.name }}</p>
          <p class="">
            {{ question.category.name }}
          </p>
        </desc-data>
      </desc-item>
      <desc-item>
        <desc-title> Question </desc-title>
        <desc-data>
          <p class="mb-4 text-lg italic">
            {{ question.title }}
          </p>
          <p class="leading-normal text-gray-900" v-html="question.question" />
        </desc-data>
      </desc-item>
      <desc-item v-if="question.answer">
        <desc-title> Answer (public) </desc-title>
        <desc-data>
          <p v-html="question.answer" />
        </desc-data>
      </desc-item>
      <desc-item v-if="question.answer_private">
        <desc-title> Answer (private) </desc-title>
        <desc-data>
          <p v-html="question.answer_private" />
        </desc-data>
      </desc-item>
      <desc-item v-if="question.veterinary">
        <desc-title> Answered by </desc-title>
        <desc-data>
          <p>
            {{ question.veterinary ? question.veterinary.display_name : ''
            }}<br />{{ $formatDate(question.updated_at) }}
          </p>
        </desc-data>
      </desc-item>
      <desc-item>
        <desc-title></desc-title>
        <desc-data>
          <base-button class="block w-full" @click="goToQuestion(question.id)">
            <div class="inline-flex items-center space-x-2">
              <fv-icon icon="edit-alt" />
              <span>Edit </span>
            </div>
          </base-button>
        </desc-data>
      </desc-item>
    </desc-list>
  </div>
</template>

<script>
import '@/utils/helpers/time-helpers';

export default {
  props: {
    question: {
      type: Object,
      required: true,
    },
  },
  methods: {
    goToQuestion(id) {
      this.$router.push({ name: 'question', params: { id } });
    },
  },
};
</script>

<style lang="postcss"></style>
