var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "desc-list",
        [
          _c(
            "desc-item",
            [
              _c("desc-title", [_vm._v(" Status ")]),
              _c("desc-data", [
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.question.status == 1 ? "Published" : "Unpublished"
                      ) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.question.answered == 1 ? "Answered" : "Unanswered"
                      ) +
                      " "
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "desc-item",
            [
              _c("desc-title", [_vm._v(" Animal ")]),
              _c("desc-data", [
                _c("p", [_vm._v(_vm._s(_vm.question.animal.name))]),
                _c("p", {}, [
                  _vm._v(" " + _vm._s(_vm.question.category.name) + " "),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "desc-item",
            [
              _c("desc-title", [_vm._v(" Question ")]),
              _c("desc-data", [
                _c("p", { staticClass: "mb-4 text-lg italic" }, [
                  _vm._v(" " + _vm._s(_vm.question.title) + " "),
                ]),
                _c("p", {
                  staticClass: "leading-normal text-gray-900",
                  domProps: { innerHTML: _vm._s(_vm.question.question) },
                }),
              ]),
            ],
            1
          ),
          _vm.question.answer
            ? _c(
                "desc-item",
                [
                  _c("desc-title", [_vm._v(" Answer (public) ")]),
                  _c("desc-data", [
                    _c("p", {
                      domProps: { innerHTML: _vm._s(_vm.question.answer) },
                    }),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.question.answer_private
            ? _c(
                "desc-item",
                [
                  _c("desc-title", [_vm._v(" Answer (private) ")]),
                  _c("desc-data", [
                    _c("p", {
                      domProps: {
                        innerHTML: _vm._s(_vm.question.answer_private),
                      },
                    }),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.question.veterinary
            ? _c(
                "desc-item",
                [
                  _c("desc-title", [_vm._v(" Answered by ")]),
                  _c("desc-data", [
                    _c("p", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.question.veterinary
                              ? _vm.question.veterinary.display_name
                              : ""
                          )
                      ),
                      _c("br"),
                      _vm._v(
                        _vm._s(_vm.$formatDate(_vm.question.updated_at)) + " "
                      ),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "desc-item",
            [
              _c("desc-title"),
              _c(
                "desc-data",
                [
                  _c(
                    "base-button",
                    {
                      staticClass: "block w-full",
                      on: {
                        click: function ($event) {
                          return _vm.goToQuestion(_vm.question.id)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "inline-flex items-center space-x-2" },
                        [
                          _c("fv-icon", { attrs: { icon: "edit-alt" } }),
                          _c("span", [_vm._v("Edit ")]),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }