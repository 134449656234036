<template>
  <default-layout>
    <div class="mx-auto">
      <h1 class="mb-6 font-display text-4xl">Questions</h1>

      <questions-table
        v-if="questions.data"
        class="relative"
        :pagination-list="questions"
        :theads="['ID', 'Question', 'User', 'Animal', 'Status', '']"
      >
        <div slot="heading" class="flex w-full justify-between">
          <spinner-overlay color="transparent" :loading="loading" size="xl" />
          <form class="flex flex-1 justify-end" @submit.prevent="submit">
            <div class="flex w-full items-center justify-center">
              <base-select
                v-model="status"
                class="mr-2 flex-1"
                :options="statuses"
                @change="(status = $event), submit()"
              />
              <base-select
                v-model="answered"
                class="mr-2 flex-1"
                :options="answeredOptions"
                @change="(answered = $event), submit()"
              />
              <base-select
                v-model="category"
                class="mr-2 flex-1"
                :options="categoryList"
                @change="(category = $event), submit()"
              />
              <div class="inline-flex w-1/2 items-center space-x-1">
                <base-input
                  v-model="searchstr"
                  class="w-1/2"
                  placeholder="Search by keywords.."
                />
                <base-button color="dark" type="submit">
                  <div class="inline-flex items-center space-x-2">
                    <fv-icon icon="search" /> <span>Search</span>
                  </div>
                </base-button>
                <base-button v-if="searched" color="cancel" @click="reset">
                  <fv-icon icon="switch" class="h-4 w-4" />
                </base-button>
              </div>
            </div>
          </form>
        </div>
        <div slot="footer">
          <pagination-buttons
            v-if="questions"
            :pagination-list="questions"
            @next="fetchMore(next_page_url)"
            @previous="fetchMore(prev_page_url)"
          />
        </div>
      </questions-table>
    </div>
  </default-layout>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import QuestionsTable from '@/components/questions/QuestionsTable.vue';
import PaginationButtons from '@/UI/PaginationButtons.vue';

export default {
  components: {
    QuestionsTable,
    PaginationButtons,
  },
  data() {
    return {
      list: {
        data: [],
      },
      category: 0,
      answered: '',
      status: '',
      statuses: [
        {
          id: '',
          name: 'All',
        },
        {
          id: 1,
          name: 'Published',
        },
        {
          id: 0,
          name: 'Unpublished',
        },
      ],
      answeredOptions: [
        {
          id: '',
          name: 'All',
        },
        {
          id: '1',
          name: 'Answered',
        },
        {
          id: '0',
          name: 'Unanswered',
        },
      ],
      searchstr: '',
      searched: false,
      loading: false,
    };
  },
  watch: {
    countryId: function () {
      this.fetchInitial();
    },
  },
  computed: {
    ...mapState('admin', ['countryId']),
    ...mapGetters('question', {
      questions: 'getQuestions',
      categories: 'getCategories',
      prev_page_url: 'prev_page_url',
      next_page_url: 'next_page_url',
      last_page: 'last_page',
      current_page: 'current_page',
    }),
    categoryList() {
      return this.categories.map((cat, index) => {
        return {
          id: index,
          name: cat,
        };
      });
    },
  },
  mounted() {
    this.fetchInitial();
  },
  methods: {
    ...mapActions('question', [
      'fetchQuestions',
      'queryQuestions',
      'fetchMoreQuestions',
    ]),
    submit() {
      this.searched = !!(this.category || this.searchstr || this.status);
      const payload = {
        status: this.status,
        category: this.category,
        search: this.searchstr,
        answered: this.answered,
        perPage: 8,
        countryId: this.countryId,
      };
      this.loading = true;
      this.queryQuestions(payload)
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    fetchInitial() {
      this.loading = true;
      let payload = {
        countryId: this.countryId,
      };
      this.fetchQuestions(payload)
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    fetchMore(url) {
      this.loading = true;
      this.fetchMoreQuestions(url)
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    reset() {
      this.searched = false;
      this.category = 0;
      this.searchstr = '';
      this.status = '';
      this.answered = '';
      this.fetchInitial();
    },
  },
};
</script>

<style lang="postcss"></style>
