var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("default-layout", [
    _c(
      "div",
      { staticClass: "mx-auto" },
      [
        _c("h1", { staticClass: "mb-6 font-display text-4xl" }, [
          _vm._v("Questions"),
        ]),
        _vm.questions.data
          ? _c(
              "questions-table",
              {
                staticClass: "relative",
                attrs: {
                  "pagination-list": _vm.questions,
                  theads: ["ID", "Question", "User", "Animal", "Status", ""],
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "flex w-full justify-between",
                    attrs: { slot: "heading" },
                    slot: "heading",
                  },
                  [
                    _c("spinner-overlay", {
                      attrs: {
                        color: "transparent",
                        loading: _vm.loading,
                        size: "xl",
                      },
                    }),
                    _c(
                      "form",
                      {
                        staticClass: "flex flex-1 justify-end",
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return _vm.submit.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex w-full items-center justify-center",
                          },
                          [
                            _c("base-select", {
                              staticClass: "mr-2 flex-1",
                              attrs: { options: _vm.statuses },
                              on: {
                                change: function ($event) {
                                  ;(_vm.status = $event), _vm.submit()
                                },
                              },
                              model: {
                                value: _vm.status,
                                callback: function ($$v) {
                                  _vm.status = $$v
                                },
                                expression: "status",
                              },
                            }),
                            _c("base-select", {
                              staticClass: "mr-2 flex-1",
                              attrs: { options: _vm.answeredOptions },
                              on: {
                                change: function ($event) {
                                  ;(_vm.answered = $event), _vm.submit()
                                },
                              },
                              model: {
                                value: _vm.answered,
                                callback: function ($$v) {
                                  _vm.answered = $$v
                                },
                                expression: "answered",
                              },
                            }),
                            _c("base-select", {
                              staticClass: "mr-2 flex-1",
                              attrs: { options: _vm.categoryList },
                              on: {
                                change: function ($event) {
                                  ;(_vm.category = $event), _vm.submit()
                                },
                              },
                              model: {
                                value: _vm.category,
                                callback: function ($$v) {
                                  _vm.category = $$v
                                },
                                expression: "category",
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "inline-flex w-1/2 items-center space-x-1",
                              },
                              [
                                _c("base-input", {
                                  staticClass: "w-1/2",
                                  attrs: {
                                    placeholder: "Search by keywords..",
                                  },
                                  model: {
                                    value: _vm.searchstr,
                                    callback: function ($$v) {
                                      _vm.searchstr = $$v
                                    },
                                    expression: "searchstr",
                                  },
                                }),
                                _c(
                                  "base-button",
                                  { attrs: { color: "dark", type: "submit" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "inline-flex items-center space-x-2",
                                      },
                                      [
                                        _c("fv-icon", {
                                          attrs: { icon: "search" },
                                        }),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("Search")]),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _vm.searched
                                  ? _c(
                                      "base-button",
                                      {
                                        attrs: { color: "cancel" },
                                        on: { click: _vm.reset },
                                      },
                                      [
                                        _c("fv-icon", {
                                          staticClass: "h-4 w-4",
                                          attrs: { icon: "switch" },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { attrs: { slot: "footer" }, slot: "footer" },
                  [
                    _vm.questions
                      ? _c("pagination-buttons", {
                          attrs: { "pagination-list": _vm.questions },
                          on: {
                            next: function ($event) {
                              return _vm.fetchMore(_vm.next_page_url)
                            },
                            previous: function ($event) {
                              return _vm.fetchMore(_vm.prev_page_url)
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }