var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mx-auto my-0 w-full" },
    [
      _c(
        "table-container",
        {
          attrs: {
            loading: _vm.loading,
            theads: _vm.theads,
            "columns-to-hide": _vm.columnToHide,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "w-full",
              attrs: { slot: "tableHeader" },
              slot: "tableHeader",
            },
            [_vm._t("heading")],
            2
          ),
          _vm._l(_vm.questions, function (question) {
            return _c(
              "tr",
              {
                key: question.id,
                staticClass:
                  "text-sm hover:bg-beigeLighter hover:bg-opacity-50",
              },
              [
                _c("table-data", [_vm._v(" " + _vm._s(question.id) + " ")]),
                _c("table-data", [
                  _c(
                    "button",
                    {
                      staticClass:
                        "group focus:outline-none flex flex-col space-y-1",
                      on: {
                        click: function ($event) {
                          ;(_vm.showQuestion = true),
                            (_vm.selectedQuestion = question)
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "block text-sm text-gray-600" },
                        [_vm._v(" " + _vm._s(question.created_at) + " ")]
                      ),
                      _c(
                        "p",
                        { staticClass: "font-semibold text-primary-darker" },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.trimText(question.title, 50)) + " "
                          ),
                        ]
                      ),
                      _c("p", { staticClass: "max-w-xs truncate text-sm" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.getQuestionPreviewText(question)) +
                            " "
                        ),
                      ]),
                    ]
                  ),
                ]),
                _vm.showColumns
                  ? _c("table-data", [
                      _c("div", { staticClass: "flex flex-col space-y-1" }, [
                        _c("span", { staticClass: "block" }, [
                          _vm._v(_vm._s(question.customer.display_name)),
                        ]),
                        _c("span", { staticClass: "block text-sm" }, [
                          _vm._v(_vm._s(question.customer.email)),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _vm.showColumns
                  ? _c("table-data", [
                      _c("div", { staticClass: "flex flex-col space-y-1" }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              question.animal ? question.animal.name : "No name"
                            )
                          ),
                        ]),
                        _c("span", { staticClass: "text-sm" }, [
                          _vm._v(_vm._s(_vm.getAnimalTranslation(question))),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _c("table-data", [
                  _c("div", { staticClass: "flex flex-col space-y-2" }, [
                    _c(
                      "div",
                      { staticClass: "inline-flex items-center space-x-1" },
                      [
                        _c("fv-icon", {
                          staticClass: "h-4 w-4",
                          class:
                            question.answered === 1
                              ? "text-fv-green"
                              : "text-yellow-800",
                          attrs: {
                            icon:
                              question.answered === 1
                                ? "check-mark"
                                : "warning",
                          },
                        }),
                        !question.answered
                          ? _c("span", [_vm._v("Not answered")])
                          : _vm._e(),
                        question.answered
                          ? _c("span", [_vm._v("Answered")])
                          : _vm._e(),
                      ],
                      1
                    ),
                    question.status
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "inline-flex items-center space-x-1 text-sm",
                          },
                          [
                            _c("fv-icon", {
                              staticClass: "h-4 w-4 text-primary-darker",
                              attrs: { icon: "notification" },
                            }),
                            _c("span", [_vm._v("Published")]),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ]),
                _c("table-data", [
                  _c(
                    "div",
                    { staticClass: "flex flex-col space-y-2" },
                    [
                      _c(
                        "base-button",
                        {
                          staticClass: "block w-full",
                          on: {
                            click: function ($event) {
                              return _vm.goToQuestion(question.id)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "inline-flex items-center space-x-2",
                            },
                            [
                              _c("fv-icon", { attrs: { icon: "edit-alt" } }),
                              _c("span", [_vm._v("Edit ")]),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            )
          }),
          _c(
            "div",
            { attrs: { slot: "tableFooter" }, slot: "tableFooter" },
            [_vm._t("footer")],
            2
          ),
        ],
        2
      ),
      _vm.selectedQuestion
        ? _c(
            "base-modal",
            {
              attrs: { show: _vm.showQuestion },
              on: {
                close: function ($event) {
                  ;(_vm.showQuestion = false), (_vm.question = null)
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "flex p-4" },
                [
                  _c("question-preview", {
                    attrs: { question: _vm.selectedQuestion },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }