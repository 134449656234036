<template>
  <div class="mx-auto my-0 w-full">
    <table-container
      :loading="loading"
      :theads="theads"
      :columns-to-hide="columnToHide"
    >
      <div slot="tableHeader" class="w-full">
        <slot name="heading" />
      </div>
      <tr
        v-for="question in questions"
        :key="question.id"
        class="text-sm hover:bg-beigeLighter hover:bg-opacity-50"
      >
        <table-data>
          {{ question.id }}
        </table-data>
        <table-data>
          <button
            class="group focus:outline-none flex flex-col space-y-1"
            @click="(showQuestion = true), (selectedQuestion = question)"
          >
            <span class="block text-sm text-gray-600">
              {{ question.created_at }}
            </span>

            <p class="font-semibold text-primary-darker">
              {{ trimText(question.title, 50) }}
            </p>
            <p class="max-w-xs truncate text-sm">
              {{ getQuestionPreviewText(question) }}
            </p>
          </button>
        </table-data>

        <table-data v-if="showColumns">
          <div class="flex flex-col space-y-1">
            <span class="block">{{ question.customer.display_name }}</span>
            <span class="block text-sm">{{ question.customer.email }}</span>
          </div>
        </table-data>

        <table-data v-if="showColumns">
          <div class="flex flex-col space-y-1">
            <span>{{
              question.animal ? question.animal.name : 'No name'
            }}</span>
            <span class="text-sm">{{ getAnimalTranslation(question) }}</span>
          </div>
        </table-data>

        <table-data>
          <div class="flex flex-col space-y-2">
            <div class="inline-flex items-center space-x-1">
              <fv-icon
                class="h-4 w-4"
                :icon="question.answered === 1 ? 'check-mark' : 'warning'"
                :class="
                  question.answered === 1 ? 'text-fv-green' : 'text-yellow-800'
                "
              />
              <span v-if="!question.answered">Not answered</span>
              <span v-if="question.answered">Answered</span>
            </div>

            <div
              v-if="question.status"
              class="inline-flex items-center space-x-1 text-sm"
            >
              <fv-icon
                class="h-4 w-4 text-primary-darker"
                icon="notification"
              />
              <span>Published</span>
            </div>
          </div>
        </table-data>

        <table-data>
          <div class="flex flex-col space-y-2">
            <base-button
              class="block w-full"
              @click="goToQuestion(question.id)"
            >
              <div class="inline-flex items-center space-x-2">
                <fv-icon icon="edit-alt" />
                <span>Edit </span>
              </div>
            </base-button>
          </div>
        </table-data>
      </tr>
      <div slot="tableFooter">
        <slot name="footer" />
      </div>
    </table-container>
    <base-modal
      v-if="selectedQuestion"
      :show="showQuestion"
      @close="(showQuestion = false), (question = null)"
    >
      <div class="flex p-4">
        <question-preview :question="selectedQuestion" />
      </div>
    </base-modal>
  </div>
</template>

<script>
import BaseModal from '@/UI/modals/base-modal';
import QuestionPreview from '@/components/questions/QuestionPreview';

export default {
  components: {
    BaseModal,
    QuestionPreview,
  },
  props: {
    paginationList: {
      type: Object,
      required: true,
    },
    theads: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showQuestion: false,
      selectedQuestion: null,
      columnToHide: [],
    };
  },
  computed: {
    questions() {
      return this.paginationList.data;
    },
    showColumns() {
      return this.columnToHide.length === 0;
    },
  },
  created() {
    this.getColumnToHide();
    window.addEventListener('resize', this.getColumnToHide);
  },
  destroyed() {
    window.removeEventListener('resize', this.getColumnToHide);
  },
  methods: {
    shouldHideColumns() {
      let viewport_width = document.documentElement.clientWidth;
      return viewport_width < 1280;
    },
    getColumnToHide() {
      if (this.shouldHideColumns()) {
        this.columnToHide = [2, 3];
        return;
      }
      this.columnToHide = [];
    },
    trimText(comment, maxTextLength = 250) {
      const index = comment.indexOf(' ', maxTextLength);
      return index === -1 ? comment : comment.substring(0, index) + '...';
    },
    getAnimalTranslation(question) {
      return question.category?.animal_type_translation
        ? question.category.animal_type_translation.name
        : question.category.name;
    },
    getQuestionPreviewText(question) {
      const questionHtml = question.question;
      const tempSpan = document.createElement('span');
      tempSpan.innerHTML = questionHtml;
      const noHtmlQuestionText = tempSpan.innerText;
      return noHtmlQuestionText;
    },
    goToQuestion(id) {
      this.$router.push({ name: 'question', params: { id } });
    },
  },
};
</script>
